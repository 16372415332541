var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12 col-xxl-12" }, [
      _c("div", { staticClass: "card card-custom card-stretch gutter-b" }, [
        _c(
          "div",
          { staticClass: "info-container position-relative" },
          [
            _vm.showMainLoader ? _c("LoaderPartial") : _vm._e(),
            _c("div", { staticClass: "tenant-header" }, [
              _c("div", {
                staticClass: "tenant-image",
                style: "background-image:url('" + _vm.current_photo + "');"
              }),
              _c("h1", { staticClass: "tenant-name" }, [
                _vm._v(_vm._s(_vm.client.name))
              ])
            ]),
            _c(
              "div",
              { staticClass: "d-flex flex-row justify-content-around" },
              [
                _c("div", { staticClass: "p-2" }, [
                  _c("p", [
                    _vm._v(" Nombre Cliente: "),
                    _c("strong", [
                      _vm._v(" " + _vm._s(_vm.client.client) + " ")
                    ])
                  ]),
                  _c("p", [
                    _vm._v(" Fecha Alta: "),
                    _c("strong", [
                      _vm._v(
                        " " +
                          _vm._s(
                            new Date(_vm.client.createdAt).toLocaleDateString(
                              "es-ES"
                            )
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c("p", [
                    _vm._v(" Facturas Emitidas: "),
                    _c("strong", [_vm._v(" " + _vm._s(_vm.bills.length) + " ")])
                  ])
                ]),
                _c("div", { staticClass: "p-2" }, [
                  _c("p", [
                    _vm._v(" Créditos Disponibles: "),
                    _c("strong", [
                      _vm._v(" " + _vm._s(_vm.wallet.credits) + " ")
                    ])
                  ]),
                  _c("p", [
                    _vm._v(" Total Request: "),
                    _c("strong", [
                      _vm._v(" " + _vm._s(_vm.requestHistoryCount) + " ")
                    ])
                  ]),
                  _c("p", [
                    _vm._v(" Fecha Último Request: "),
                    _c("strong", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.requestHistoryLatest.length > 0
                              ? new Date(
                                  _vm.requestHistoryLatest[0].createdAt
                                ).toLocaleDateString("es-ES")
                              : "NA"
                          ) +
                          " "
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "p-2" }, [
                  _c("p", [
                    _vm._v(" Número Aplicaciones: "),
                    _c("strong", [_vm._v(" " + _vm._s(_vm.apps.length) + " ")])
                  ]),
                  _c("p", [
                    _vm._v(" Modelos Utilizados: "),
                    _c("strong", [
                      _vm._v(
                        " " + _vm._s(_vm.charts.models.series.length) + " "
                      )
                    ])
                  ]),
                  _c("p", [
                    _vm._v(" Total Facturación: "),
                    _c("strong", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.bills
                              .reduce(function(sum, item) {
                                return sum + item.amount
                              }, 0)
                              .toFixed(2)
                          ) +
                          " "
                      )
                    ])
                  ])
                ])
              ]
            )
          ],
          1
        ),
        _c("div", { staticClass: "charts-container" }, [
          _c(
            "div",
            {
              staticClass:
                "chart-container-outer position-relative col-12 col-md-9",
              class: _vm.charts.perDate.expanded ? "expanded" : ""
            },
            [
              _vm.showchartsLoader.requestPerDateChart
                ? _c("LoaderPartial")
                : _vm._e(),
              _c(
                "div",
                { staticClass: "chart-container-inner" },
                [
                  _c("VueApexCharts", {
                    attrs: {
                      type: "area",
                      options: _vm.charts.perDate.options,
                      series: _vm.charts.perDate.series
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "custom-apexcharts-menu apexcharts-menu",
                      class: _vm.charts.perDate.showIntervalMenu
                        ? "apexcharts-menu-open"
                        : ""
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "apexcharts-menu-item",
                          on: {
                            click: function($event) {
                              return _vm.updateTotalsDataInterval("days")
                            }
                          }
                        },
                        [_vm._v("Intervalo en Días")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "apexcharts-menu-item",
                          on: {
                            click: function($event) {
                              return _vm.updateTotalsDataInterval("weeks")
                            }
                          }
                        },
                        [_vm._v("Intervalo en Semanas")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "apexcharts-menu-item",
                          on: {
                            click: function($event) {
                              return _vm.updateTotalsDataInterval("months")
                            }
                          }
                        },
                        [_vm._v("Intervalo en Meses")]
                      )
                    ]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "chart-title" }, [
                _vm._v("Peticiones Totales")
              ])
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "chart-container-outer position-relative col-12 col-md-5",
              class: _vm.charts.perApp.expanded ? "expanded" : ""
            },
            [
              _vm.showchartsLoader.requestPerAppChart
                ? _c("LoaderPartial")
                : _vm._e(),
              _c(
                "div",
                { staticClass: "chart-container-inner position-relative" },
                [
                  _c("VueApexCharts", {
                    attrs: {
                      type: "donut",
                      options: _vm.charts.perApp.options,
                      series: _vm.charts.perApp.series
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "chart-title" }, [
                _vm._v("Peticiones por Cliente")
              ])
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "chart-container-outer position-relative col-12 col-md-5",
              class: _vm.charts.perService.expanded ? "expanded" : ""
            },
            [
              _vm.showchartsLoader.requestPerServiceChart
                ? _c("LoaderPartial")
                : _vm._e(),
              _c(
                "div",
                { staticClass: "chart-container-inner" },
                [
                  _c("VueApexCharts", {
                    attrs: {
                      type: "donut",
                      options: _vm.charts.perService.options,
                      series: _vm.charts.perService.series
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "chart-title" }, [
                _vm._v("Peticiones por Servicio")
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }