<style lang="scss" scoped>
.tenant-header {
  display: flex;
  padding: 1em 2em;
  .tenant-image {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 10em;
  }
  .tenant-name {
    padding: 2em 1em;
  }
}
.overview-container {
  padding: 1em 2em;
  margin-bottom: 4em;
  display: flex;
  @media screen and (max-width: 730px) {
    flex-direction: column;
  }
  .overview-group {
    flex: 1 1 0px;
    font-size: 1.15em;
    td {
      &.details-button {
        font-size: 70%;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    @media screen and (max-width: 730px) {
      td {
        width: 50%;
        padding: 0.5em;
      }
    }
  }
}
.charts-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 1em 2em;
    justify-content: center;
    @media screen and (max-width: 730px) {
      flex-direction: column;
    }
  .chart-container-outer {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3em;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    &.expanded {
      width: 75%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    .chart-container-inner {
      width: 100%;
      .custom-apexcharts-menu.apexcharts-menu {
        top: 2em;
      }
    }
    .chart-title {
      font-weight: bold;
    }
  }
}

</style>
<style lang="scss">
.charts-container {
  .apexcharts-toolbar {
    .apexcharts-element-hidden {
      display: none;
    }
  }
  .chart-toolbar-icon {
    width: 17px;
    height: 17px;
    margin-left: 4px;
    margin-right: 3px;
    &:hover {
      i {
        color: #333;
      }
    }
    i {
      color: #6E8192;
    }
    &.mobile-hidden {
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
  i {
    &.chart-download-icon {
      width:20px;
      font-size:1.4em;
      margin-top:0.0625em;
      color: #6E8192;
      &:hover {
        color: #333;
      }
    }
  }
}
</style>

<template>
  <div class="row">
    <div class="col-lg-12 col-xxl-12">
      <div class="card card-custom card-stretch gutter-b">
        <div class="info-container position-relative">
          <LoaderPartial v-if="showMainLoader"></LoaderPartial>
          <div class="tenant-header">
            <div
              class="tenant-image"
              :style="'background-image:url(\'' + current_photo + '\');'"
            ></div>
            <h1 class="tenant-name">{{ client.name }}</h1>
          </div>

          <div class="d-flex flex-row justify-content-around">
            <div class="p-2">
              <p> Nombre Cliente: <strong> {{ client.client }} </strong> </p> 
              <p> Fecha Alta: <strong> {{ new Date(client.createdAt).toLocaleDateString("es-ES")}} </strong> </p>          
              <p> Facturas Emitidas: <strong> {{ bills.length }} </strong> </p>    
            </div>
            <div class="p-2">
              <p> Créditos Disponibles: <strong> {{ wallet.credits }} </strong> </p> 
              <p> Total Request: <strong> {{ requestHistoryCount }} </strong> </p>          
              <p> Fecha Último Request: 
                <strong> {{ requestHistoryLatest.length > 0 ? 
                            new Date(requestHistoryLatest[0].createdAt).toLocaleDateString("es-ES") : "NA" }} 
                </strong> 
              </p>  
            </div>    
            <div class="p-2">
              <p> Número Aplicaciones: <strong> {{ apps.length }} </strong> </p> 
              <p> Modelos Utilizados: <strong> {{ charts.models.series.length }} </strong> </p>          
              <p> Total Facturación:
                <strong> 
                  {{ bills.reduce((sum, item) => sum + item.amount, 0).toFixed(2) }}
                </strong> 
              </p>            
            </div>
          </div>
          
        </div>
        <div class="charts-container">
          <div class="chart-container-outer position-relative col-12 col-md-9" :class="charts.perDate.expanded ? 'expanded' : ''">
            <LoaderPartial v-if="showchartsLoader.requestPerDateChart"></LoaderPartial>
            <!-- {{charts.totals.series}} -->
            <div class="chart-container-inner">
              <VueApexCharts type="area" :options="charts.perDate.options" :series="charts.perDate.series"></VueApexCharts>
              <div class="custom-apexcharts-menu apexcharts-menu" :class="charts.perDate.showIntervalMenu ? 'apexcharts-menu-open' : ''">
                <div class="apexcharts-menu-item" @click="updateTotalsDataInterval('days')">Intervalo en Días</div>
                <div class="apexcharts-menu-item" @click="updateTotalsDataInterval('weeks')">Intervalo en Semanas</div>
                <div class="apexcharts-menu-item" @click="updateTotalsDataInterval('months')">Intervalo en Meses</div>
              </div>
            </div>
            <div class="chart-title">Peticiones Totales</div>
          </div>



          <div class="chart-container-outer position-relative col-12 col-md-5" :class="charts.perApp.expanded ? 'expanded' : ''">
            <!-- {{charts.clients.series}} -->
            <LoaderPartial v-if="showchartsLoader.requestPerAppChart"></LoaderPartial>
            <div class="chart-container-inner position-relative">
              <VueApexCharts type="donut" :options="charts.perApp.options" :series="charts.perApp.series"></VueApexCharts>
            </div>
            <div class="chart-title">Peticiones por Cliente</div>
          </div>


          <div class="chart-container-outer position-relative col-12 col-md-5" :class="charts.perService.expanded ? 'expanded' : ''">
            <LoaderPartial v-if="showchartsLoader.requestPerServiceChart"></LoaderPartial>
            <!-- {{charts.models.series}} -->
            <div class="chart-container-inner">
              <VueApexCharts type="donut" :options="charts.perService.options" :series="charts.perService.series"></VueApexCharts>
            </div>
            <div class="chart-title">Peticiones por Servicio</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import LoaderPartial from "@/view/content/LoaderPartial.vue";
import VueApexCharts from 'vue-apexcharts';
import { countBy, findLast } from 'lodash';

const RESOURCE = "tenancies";
const chartLocaleEs = require('apexcharts/dist/locales/es.json');

export default {
  name: "DashboardClient",
  components: {
    LoaderPartial,
    VueApexCharts
  },
  data() {
    return {
      resource: RESOURCE,
      client: {
        image: null,
        client: "",
        name: "",
        description: "",
        tenant: "",
        credits: 1
      },
      apps: [],
      wallet: {},
      bills: [],
      requestHistory: [],
      requestHistoryLatest: {},
      requestHistoryCount: 0,
      default_photo: "media/users/blank.png",
      current_photo: null,
      showMainLoader: true,
      showchartsLoader: {
        requestHistoryChart: true,
        requestModelChart: true,
        totalRequestChart: true,

        requestPerServiceChart: true,
        requestPerAppChart: true,
        requestPerDateChart: true,
      },
      charts: {
        clients: {
          expanded: false,
          options: {
            theme: {
              palette: "palette5"
            },
            chart: {
              // height: '350px',
              // width: '100%',
              defaultLocale: 'es',
              locales: [chartLocaleEs],
              toolbar: {
                show: true,
                tools: {
                  download: '<i class="fas fa-download chart-download-icon" style=""></i>',
                  customIcons: [
                    {
                      icon: '<i class="fas fa-expand-arrows-alt"></i>',
                      index: -1,
                      title: 'Expandir',
                      class: 'chart-toolbar-icon mobile-hidden',
                      click: () => {
                        this.charts.clients.expanded = this.charts.clients.expanded ? false : true;
                        this.charts.clients.options.chart.toolbar.tools.customIcons[0].icon = this.charts.clients.expanded ? '<i class="fas fa-compress-arrows-alt"></i>' : '<i class="fas fa-expand-arrows-alt"></i>';
                      }
                    }
                  ]
                }
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            tooltip: {
              enabled: false
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      show: true
                    }
                  }
                }
              }
            },
            labels: []
          },
          series: [],
        },
        models: {
          expanded: false,
          options: {
            theme: {
              palette: "palette5"
            },
            chart: {
              // height: '350px',
              // width: '100%',
              defaultLocale: 'es',
              locales: [chartLocaleEs],
              toolbar: {
                show: true,
                tools: {
                  download: '<i class="fas fa-download chart-download-icon" style=""></i>',
                  customIcons: [
                    {
                      icon: '<i class="fas fa-expand-arrows-alt"></i>',
                      index: -1,
                      title: 'Expandir',
                      class: 'chart-toolbar-icon mobile-hidden',
                      click: () => {
                        this.charts.models.expanded = this.charts.models.expanded ? false : true;
                        this.charts.models.options.chart.toolbar.tools.customIcons[0].icon = this.charts.models.expanded ? '<i class="fas fa-compress-arrows-alt"></i>' : '<i class="fas fa-expand-arrows-alt"></i>';
                      }
                    }
                  ]
                }
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            tooltip: {
              enabled: false
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      show: true
                    }
                  }
                }
              }
            },
            labels: []
          },
          series: [],
        },
        totals: {
          expanded: false,
          showIntervalMenu: false,
          options: {
            colors: ['#FF3670'],
            stroke: {
              curve: 'stepline'
            },
            chart: {
              // height: '350px',
              // width: '100%'
              defaultLocale: 'es',
              locales: [chartLocaleEs],
              toolbar: {
                show: true,
                tools: {
                  download: '<i class="fas fa-download chart-download-icon" style=""></i>',
                  customIcons: [
                    {
                      icon: '<i class="fas fa-expand-arrows-alt"></i>',
                      index: 5,
                      title: 'Expandir',
                      class: 'chart-toolbar-icon mobile-hidden',
                      click: () => {
                        this.charts.totals.expanded = this.charts.totals.expanded ? false : true;
                        this.charts.totals.options.chart.toolbar.tools.customIcons[0].icon = this.charts.totals.expanded ? '<i class="fas fa-compress-arrows-alt"></i>' : '<i class="fas fa-expand-arrows-alt"></i>';
                      }
                    },
                    {
                      icon: '<i class="fas fa-calendar-day"></i>',
                      index: 5,
                      title: 'Cambiar Intervalo',
                      class: 'chart-toolbar-icon',
                      click: () => {
                        this.charts.totals.showIntervalMenu = true;
                      }
                    }
                  ]
                }
              },
              events: {
                click: () => {
                  this.charts.totals.showIntervalMenu = false;
                }
              }
            },
            xaxis: {
              type: 'datetime',
              // labels: {
              //   datetimeUTC: false
              // }
            },
            dataLabels: {
              enabled: false
            }
          },
          series:[{
            data: [{}]
          }]
        },

        perApp: {
          expanded: false,
          options: {
            theme: {
              palette: "palette5"
            },
            chart: {
              // height: '350px',
              // width: '100%',
              defaultLocale: 'es',
              locales: [chartLocaleEs],
              toolbar: {
                show: true,
                tools: {
                  download: '<i class="fas fa-download chart-download-icon" style=""></i>',
                  customIcons: [
                    {
                      icon: '<i class="fas fa-expand-arrows-alt"></i>',
                      index: -1,
                      title: 'Expandir',
                      class: 'chart-toolbar-icon mobile-hidden',
                      click: () => {
                        this.charts.perApp.expanded = this.charts.perApp.expanded ? false : true;
                        this.charts.perApp.options.chart.toolbar.tools.customIcons[0].icon = this.charts.perApp.expanded ? '<i class="fas fa-compress-arrows-alt"></i>' : '<i class="fas fa-expand-arrows-alt"></i>';
                      }
                    }
                  ]
                }
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            tooltip: {
              enabled: false
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      show: true
                    }
                  }
                }
              }
            },
            labels: []
          },
          series: [],
        },
        perService: {
          expanded: false,
          options: {
            theme: {
              palette: "palette5"
            },
            chart: {
              // height: '350px',
              // width: '100%',
              defaultLocale: 'es',
              locales: [chartLocaleEs],
              toolbar: {
                show: true,
                tools: {
                  download: '<i class="fas fa-download chart-download-icon" style=""></i>',
                  customIcons: [
                    {
                      icon: '<i class="fas fa-expand-arrows-alt"></i>',
                      index: -1,
                      title: 'Expandir',
                      class: 'chart-toolbar-icon mobile-hidden',
                      click: () => {
                        this.charts.perService.expanded = this.charts.perService.expanded ? false : true;
                        this.charts.perService.options.chart.toolbar.tools.customIcons[0].icon = this.charts.perService.expanded ? '<i class="fas fa-compress-arrows-alt"></i>' : '<i class="fas fa-expand-arrows-alt"></i>';
                      }
                    }
                  ]
                }
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            tooltip: {
              enabled: false
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      show: true
                    }
                  }
                }
              }
            },
            labels: []
          },
          series: [],
        },
        perDate: {
          expanded: false,
          showIntervalMenu: false,
          options: {
            colors: ['#FF3670'],
            stroke: {
              curve: 'stepline'
            },
            chart: {
              // height: '350px',
              // width: '100%'
              defaultLocale: 'es',
              locales: [chartLocaleEs],
              toolbar: {
                show: true,
                tools: {
                  download: '<i class="fas fa-download chart-download-icon" style=""></i>',
                  customIcons: [
                    {
                      icon: '<i class="fas fa-expand-arrows-alt"></i>',
                      index: 5,
                      title: 'Expandir',
                      class: 'chart-toolbar-icon mobile-hidden',
                      click: () => {
                        this.charts.perDate.expanded = this.charts.perDate.expanded ? false : true;
                        this.charts.perDate.options.chart.toolbar.tools.customIcons[0].icon = this.charts.perDate.expanded ? '<i class="fas fa-compress-arrows-alt"></i>' : '<i class="fas fa-expand-arrows-alt"></i>';
                      }
                    },
                    {
                      icon: '<i class="fas fa-calendar-day"></i>',
                      index: 5,
                      title: 'Cambiar Intervalo',
                      class: 'chart-toolbar-icon',
                      click: () => {
                        this.charts.perDate.showIntervalMenu = true;
                      }
                    }
                  ]
                }
              },
              events: {
                click: () => {
                  this.charts.perDate.showIntervalMenu = false;
                }
              }
            },
            xaxis: {
              type: 'datetime',
              // labels: {
              //   datetimeUTC: false
              // }
            },
            dataLabels: {
              enabled: false
            }
          },
          series:[{
            data: [{}]
          }]
        },
        
      }
    };
  },
  async mounted() {
    this.showMainLoader = true;
    try {
      this.client = await this.getClient(this.$route.params.id);
      this.wallet = await this.getWallet(this.$route.params.id);
      this.bills = await this.getBills(this.$route.params.id);
      this.requestHistoryCount = await this.getRequestHistoryCount(this.$route.params.id);
      this.requestHistoryLatest = await this.getRequestHistory(this.$route.params.id, true);
      this.wallet.operations?.sort(function(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      this.bills?.sort(function(a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      this.apps = await this.getApps(this.$route.params.id);
    } catch (e) {
      // console.log(e);
      this.$router.push({ name: "404" });
    }

    this.current_photo = this.client.image;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Clientes", route: "/clients" },
      { title: this.client.name }
    ]);
    this.showMainLoader = false;
    //this.initCharts();

    this.initRequestPerServiceChart();
    this.initRequestPerAppChart();
    this.initRequestPerDateChart();
  },
  methods: {
    async initRequestPerDateChart() {
      this.requestHistory = await this.getRequestPerDateChart(this.$route.params.id);

      let totalsData = this.getTotalsChartData();
      // console.log("totalsData", totalsData);
      this.charts.perDate.series = [{data: totalsData}];
      this.showchartsLoader.requestPerDateChart = false;


      //PER SERVICE
      
    },
    async getRequestPerDateChart(id, lastOnly = false, retry = 3) {
      try {
        
        let response = await ApiService.get(`${this.resource}/${id}/charts/date-request`);
        
        let data = [...response.data];
        // console.log("getRequestPerDateChart", data);
        return data;
      } catch (error) {
        if (retry > 0) {
          // console.log("(DashboardClient) getRequestPerDateChart Failed... retrying");
          await setTimeout(async () => {
            await this.getRequestPerDateChart(id, lastOnly, retry - 1);
          }, 200);
          return true;
        }
        // console.log(
        //   "(DashboardClient) getRequestPerDateChart Failed... no more attempts"
        // );
        // console.log(error);
        throw error;
      }
    },

    

    async initRequestPerServiceChart(){
      this.requestPerService = await this.getRequestPerServiceChart(this.$route.params.id);
      this.charts.perService.options = {...this.charts.perService.options, labels:[...this.requestPerService.map(service => service.name ? service.name : "Unnamed Service")]};
      this.charts.perService.series = this.requestPerService.map(service => service.count);
      this.showchartsLoader.requestPerServiceChart = false;
    },
    async getRequestPerServiceChart(id, lastOnly = false, retry = 3) {
      try {
        
        let response = await ApiService.get(`${this.resource}/${id}/charts/service-request`);
        
        let data = [...response.data];
        // console.log("getRequestPerServiceChart", data);
        return data;
      } catch (error) {
        if (retry > 0) {
          // console.log("(DashboardClient) getRequestPerServiceChart Failed... retrying");
          await setTimeout(async () => {
            await this.getRequestPerServiceChart(id, lastOnly, retry - 1);
          }, 200);
          return true;
        }
        // console.log(
        //   "(DashboardClient) getRequestPerServiceChart Failed... no more attempts"
        // );
        // console.log(error);
        throw error;
      }
    },

    async initRequestPerAppChart(){
      this.requestPerApp = await this.getRequestPerAppChart(this.$route.params.id);
      this.charts.perApp.options = {...this.charts.perApp.options, labels:[...this.requestPerApp.map(app => app.name ? app.name : "Unnamed App")]};
      this.charts.perApp.series = this.requestPerApp.map(app => app.count);
      this.showchartsLoader.requestPerAppChart = false;
    },
    async getRequestPerAppChart(id, lastOnly = false, retry = 3) {
      try {
        
        let response = await ApiService.get(`${this.resource}/${id}/charts/app-request`);
        
        let data = [...response.data];
        // console.log("getRequestPerAppChart", data);
        return data;
      } catch (error) {
        if (retry > 0) {
          // console.log("(DashboardClient) getRequestPerAppChart Failed... retrying");
          await setTimeout(async () => {
            await this.getRequestPerAppChart(id, lastOnly, retry - 1);
          }, 200);
          return true;
        }
        // console.log(
        //   "(DashboardClient) getRequestPerAppChart Failed... no more attempts"
        // );
        // console.log(error);
        throw error;
      }
    },



    async initCharts() {
      //this.requestHistory = await this.getRequestHistory(this.$route.params.id);
      // //clients
      
      // let clientsData = this.getClientsChartData();
      // // console.log("clientsData", clientsData);
      // // this.charts.clients.series = [{name:"Requests", data: clientsData}];
      // this.charts.clients.options = {...this.charts.models.options, labels:[...clientsData.labels]};
      // this.charts.clients.series = clientsData.count;
      // this.showchartsLoader.requestHistoryChart = false;
      // //TOTALS
      // let totalsData = this.getTotalsChartData();
      // // console.log("totalsData", totalsData);
      // this.charts.totals.series = [{data: totalsData}];
      // this.showchartsLoader.totalRequestChart = false;


      //PER SERVICE
      
    },
    getHistogramChartData() {
      let monthNames = ["No existe", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      let data = [];
      let adjustedDates = [];
      this.requestHistory.forEach(request => {
        if (request.createdAt) { 
          let date = new Date(request.createdAt);
          if(date instanceof Date && !isNaN(date.valueOf())) {
            if (date.getFullYear() === new Date().getFullYear()) {
              date.setDate(1);  
              date = date.toLocaleDateString('en-US');
              // console.log(date);
              adjustedDates.push(date);
            } 
          }
        }
      });
      data = countBy(adjustedDates);
      let count = [];
      let labels = [];
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          count.push(data[key]);
          labels.push(monthNames[parseInt(key)])
        }
      }
      // console.log("count:", count);
      // console.log("labels:", labels);
      return {count: count, labels: labels};
    },
    updateHistogramDataInterval(interval = 'days') {
      this.showchartsLoader.requestHistoryChart = true;
      this.charts.clients.series = [{name:'Requests', data: this.getHistogramChartData(interval)}];
      this.charts.clients.showIntervalMenu = false;
      this.showchartsLoader.requestHistoryChart = false;
    },
    getClientsChartData() {
      // console.log(this.requestHistory);
      let applications = [];
      this.requestHistory.forEach(request => {
        let app = request.request.application;
        
        if (app) {
          if(!applications[app.id]){
            applications[app.id] = {id: app.id, name: app.name, count: 0};
          }

          applications[app.id] = {...applications[app.id], count: applications[app.id].count+1};
        }
      });
      // console.log({
      //   count: Object.keys(applications).map((name)=>applications[name].count), 
      //   labels: Object.keys(applications).map((name)=>applications[name].name)
      // });
      return {
        count: Object.keys(applications).map((name)=>applications[name].count), 
        labels: Object.keys(applications).map((name)=>applications[name].name)
      };
      
    },
    updateClientsDataInterval() {
      this.showchartsLoader.requestHistoryChart = true;
      this.charts.clients.series = [{name:'Requests', data: this.getClientsChartData()}];
      this.charts.clients.showIntervalMenu = false;
      this.showchartsLoader.requestHistoryChart = false;
    },
    getTotalsChartData(interval = '') {
      //let data = [];
      let accumulate = [];
      //let adjustedDates = [];
      this.requestHistory.forEach(request => {
        if (request.createdAt) { 
          let date = new Date(request.createdAt);
          if(date instanceof Date && !isNaN(date.valueOf())) {  
            
            if (interval === 'months') {
              date.setDate(1);
            } else if (interval === 'weeks') {
              let weekYear = this.getISOWeekYearNumber(date);
              date = this.getDateOfISOWeek(weekYear, date.getFullYear());
              //console.log(this.getISOWeekYearNumber(date));
            } 
           
            date = date.toLocaleDateString('en-US');
            accumulate.push({x: date, y: request.count});
          }
        }   
      });
      //data = countBy(adjustedDates);
      
      // let obje = {};
      // for (let key in data) {
      //   if (Object.hasOwnProperty.call(data, key)) {
          
      //     obje = {x: key.toString(), y: data[key]};
      //     accumulate.push(obje);

      //   }
      // }
      //console.log("accum", accumulate)
      return accumulate;
    },
    updateTotalsDataInterval(interval = 'days') {
      this.showchartsLoader.requestPerDateChart = true;
      this.charts.totals.series = [{name:'Requests', data: this.getTotalsChartData(interval)}];
      this.charts.totals.showIntervalMenu = false;
      this.showchartsLoader.requestPerDateChart = false;
    },
    getModelsChartSeries() {
      let data = [];
      let serviceOnly = [];
      this.requestHistory.forEach(request => {
        serviceOnly.push(request.request.service.id);
      });
      data = countBy(serviceOnly);
      // console.log("countBy", data);
      let count = [];
      let labels = [];
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          count.push(data[key]);
          let foundService = findLast(this.requestHistory, (request) => {
            return request.request.service.id == key;
          });
          labels.push(foundService.request.service.name ? foundService.request.service.name : "/"+foundService.request.service.slug)
        }
      }
      // console.log("count:", count);
      // console.log("labels:", labels);
      return {count: count, labels: labels};
    },
    getISOWeekYearNumber(date) {
      if(date instanceof Date && !isNaN(date.valueOf())) {
        let d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        let dayNum = d.getUTCDay() || 7;
        d.setUTCDate(d.getUTCDate() + 4 - dayNum);
        let yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        return Math.ceil((((d - yearStart) / 86400000) + 1)/7);
      }
      else {
        throw '(getISOWeekYearNumber) Invalid Date!';
      }
    },
    getDateOfISOWeek(w, y) {
      let simple = new Date(y, 0, 1 + (w - 1) * 7);
      let dow = simple.getDay();
      let ISOweekStart = simple;
      if (dow <= 4)
          ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
      else
          ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
      return ISOweekStart;
    },
    async getClient(id, retry = 3) {
      try {
        let { data } = await ApiService.get(`${this.resource}`, id);
        // console.log("getClient", { ...data });
        return data;
      } catch (error) {
        if (retry > 0) {
          //console.log("(DashboardClient) getClient Failed... retrying");
          await setTimeout(async () => {
            await this.getClient(retry - 1);
          }, 200);
          return true;
        }
        //console.log("(DashboardClient) getClient Failed... no more attempts");
        //console.log(error);
        throw error;
      }
    },
    async getWallet(id, retry = 3) {
      try {
        let { data } = await ApiService.query(`${this.resource}/${id}/wallet`, {
          filter: { fields: { bills: false, operations: false } }
        });
        // console.log("getwallet", { ...data });
        return data;
      } catch (error) {
        if (retry > 0) {
          //console.log("(DashboardClient) getWallet Failed... retrying");
          await setTimeout(async () => {
            await this.getWallet(retry - 1);
          }, 200);
          return true;
        }
        // console.log("(DashboardClient) getWallet Failed... no more attempts");
        // console.log(error);
        throw error;
      }
    },
    async getBills(id, retry = 3) {
      try {
        let response = await ApiService.get(
          `${this.resource}`,
          `${id}/billing`
        );
        let data = [...response.data];
        // console.log("getBills", data);
        return data;
      } catch (error) {
        if (retry > 0) {
          // console.log("(DashboardClient) getBills Failed... retrying");
          await setTimeout(async () => {
            await this.getBills(retry - 1);
          }, 200);
          return true;
        }
        // console.log("(DashboardClient) getBills Failed... no more attempts");
        // console.log(error);
        throw error;
      }
    },
    async getApps(id, retry = 3) {
      try {
        let response = await ApiService.get(`${this.resource}`, `${id}/apps`);
        let data = [...response.data];
        // console.log("getApps", data);
        return data;
      } catch (error) {
        if (retry > 0) {
          // console.log("(DashboardClient) getApps Failed... retrying");
          await setTimeout(async () => {
            await this.getApps(id, retry - 1);
          }, 200);
          return true;
        }
        // console.log("(DashboardClient) getApps Failed... no more attempts");
        // console.log(error);
        throw error;
      }
    },
    
    
    async getRequestHistory(id, lastOnly = false, retry = 3) {
      try {
        let filter = {
          filter: {
            fields: { response: false, application: false, service: false }
          }
        };
        if (lastOnly) {
          filter.filter.limit = 1;
          filter.filter.order = "createdAt DESC";
        }
        let response = await ApiService.query(
          `${this.resource}/${id}/request-history`,
          filter
        );
        
        let data = [...response.data];
        // console.log("getRequestHistory", data);
        return data;
      } catch (error) {
        if (retry > 0) {
          // console.log("(DashboardClient) getRequestHistory Failed... retrying");
          await setTimeout(async () => {
            await this.getRequestHistory(id, lastOnly, retry - 1);
          }, 200);
          return true;
        }
        // console.log(
        //   "(DashboardClient) getRequestHistory Failed... no more attempts"
        // );
        // console.log(error);
        throw error;
      }
    },
    async getRequestHistoryCount(id, retry = 3) {
      try {
        let { data } = await ApiService.query(
          `${this.resource}/${id}/request-history/count`
        );
        // console.log("getRequestHistoryCount", data.count);
        return data.count;
      } catch (error) {
        if (retry > 0) {
          // console.log(
          //   "(DashboardClient) getRequestHistoryCount Failed... retrying"
          // );
          await setTimeout(async () => {
            await this.getRequestHistoryCount(id, retry - 1);
          }, 200);
          return true;
        }
        // console.log(
        //   "(DashboardClient) getRequestHistoryCount Failed... no more attempts"
        // );
        // console.log(error);
        throw error;
      }
    }
  },
  watch: {},
  computed: {}
};
</script>
